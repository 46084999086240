import React from 'react';
import { Routes, Route } from 'react-router-dom';

const Home = React.lazy(() => import('./Pages/Home'));
const PharmaLogistics = React.lazy(() => import('./Pages/PharmaLogistics'));
const AmazonFba = React.lazy(() => import('./Pages/AmazonFba'));

const AirFreight = React.lazy(() => import('./Pages/FreightForwarding/AirFreight'));
const OceanFreight = React.lazy(() => import('./Pages/FreightForwarding/OceanFreight'));
const Consoliation = React.lazy(() => import('./Pages/FreightForwarding/Consolidation'));
const Nvocc = React.lazy(() => import('./Pages/FreightForwarding/Nvocc'));
const CargoManagement = React.lazy(() => import('./Pages/FreightForwarding/CargoManagement'));
const CustomsBrokerage = React.lazy(() => import('./Pages/FreightForwarding/CustomsBrokerage'));

const WarehousingAndDistribution = React.lazy(() => import('./Pages/IntegratedLogistics/WarehouseAndDistribution'));
const LandFreight = React.lazy(() => import('./Pages/IntegratedLogistics/LandFreight'));
const InsuranceBrokerage = React.lazy(() => import('./Pages/IntegratedLogistics/InsuranceBrokerage'));
const ProjectCargo = React.lazy(() => import('./Pages/IntegratedLogistics/ProjectCargo'));

const AboutUs = React.lazy(() => import('./Pages/Contact/AboutUs'));
const ContactUs = React.lazy(() => import('./Pages/Contact/ContactUs'));



const Routing = () => {
    return (
        <div className='grow'>
            <React.Suspense >
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/pharmalogistics' element={<PharmaLogistics />} />
                    <Route path='/amazonfba' element={<AmazonFba />} />
                    <Route path='/aboutus' element={<AboutUs />} />
                    <Route path='/contactus' element={<ContactUs />} />
                    <Route path='/airfreight' element={<AirFreight />} />
                    <Route path='/oceanfreight' element={<OceanFreight />} />
                    <Route path='/consolidation' element={<Consoliation />} />
                    <Route path='/nvocc' element={<Nvocc />} />
                    <Route path='/cargomanagement' element={<CargoManagement />} />
                    <Route path='/customsbrokerage' element={<CustomsBrokerage />} />
                    <Route path='/warehousingdistribution' element={<WarehousingAndDistribution />} />
                    <Route path='/landfreight' element={<LandFreight />} />
                    <Route path='/insurancebrokerage' element={<InsuranceBrokerage />} />
                    <Route path='/projectcargo' element={<ProjectCargo />} />
                </Routes>
            </React.Suspense>


        </div>
    );
}
export default Routing;