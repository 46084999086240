import { useState } from 'react';
import './App.css';
import i18n from './i18n';
import LocaleContext from './LocaleContext';
import Routing from './Routing';
import { FooterComponent } from './StyledComponents/Footer/Footer';
import NavBar from './StyledComponents/NavBar/NavBar';

function App() {

  const [locale, setLocale] = useState(i18n.language);
  i18n.on('languageChanged', (lng) => setLocale(i18n.language));

  return (
    <div className='flex flex-col h-screen'>
      <LocaleContext.Provider value={{locale, setLocale}}>
        <NavBar />
        <Routing />
        <FooterComponent />
      </LocaleContext.Provider>
    </div>
  );
}

export default App;
