import { useTranslation } from "react-i18next";

export function FooterComponent() {

    const year = new Date().getFullYear();
    const { t } = useTranslation();

    return <footer className="bg-hansa-dark-blue shadow md:flex md:text-center md:justify-between p-5 lg:p-10 md:p-7">
        <p className="text-sm text-white text-center">
            {`© ${year} ${t('common_hansa_full_name')} ${t('footer_all_right_reserved')}`}
        </p>

        <p className="text-white text-sm text-center">{t('footer_focus_china')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('footer_bridge_asia')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('footer_connect_world')}</p>

    </footer>


}