
export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}


export const navigations: {[name: string]:{type: string, name: string, href: string}} = {
    "Home": {type: "button", name: 'home_nav', href: '/' },
    "PharmaLogistics": {type: "button", name: 'pharma_logistics_nav', href: '/pharmalogistics' },
    "AmazonFBA": {type: "button", name: 'amazon_fba_nav', href: '/amazonfba' },
    "FreightForwarding": {type: "panel", name: 'freight_forwarding_nav', href: ''},
    "IntegratedLogisitics": {type: "panel", name: 'integrated_logisitics_nav', href: ''},
    "Contact": {type: "panel", name: 'contact_nav', href: ''},
}

export const freightForwardingNavigations: {[name: string]:{name: string, href: string}} = {
    "AirFreight": {name: 'air_freight_nav', href: '/airfreight' },
    "OceanFreight": {name: 'ocean_freight_nav', href: '/oceanfreight' },
    "Consolidation": {name: 'consolidation_nav', href: '/consolidation' },
    "NVOCC": {name: 'nvocc_nav', href: '/nvocc'},
    "CargoManagement": {name: 'cargo_management_nav', href: '/cargomanagement'},
    "CustomsBrokerage": {name: 'customs_brokerage_nav', href: '/customsbrokerage'},
}

export const integratedLogisticsNavigations: {[name: string]:{name: string, href: string}} = {
    "warehousingAndDistribution": {name: 'warehousing_and_distribution_nav', href: '/warehousingdistribution' },
    "LandFreight": {name: 'land_freight_nav', href: '/landfreight' },
    "InsuranceBrokerage": {name: 'insurance_brokerage_nav', href: '/insurancebrokerage' },
    "Project Cargo": {name: 'project_cargo_nav', href: '/projectcargo'},
}

export const contactNavigations: {[name: string]:{name: string, href: string}} = {
    "AboutUs": {name: 'about_us_nav', href: '/aboutus' },
    "ContactUs": {name: 'contact_us_nav', href: '/contactus' },
}