import { Fragment, useContext, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, ChevronDownIcon, ChevronLeftIcon, LanguageIcon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from '../../Resources/HansaLogo.jpeg';
import { classNames, contactNavigations, freightForwardingNavigations, integratedLogisticsNavigations, navigations } from '../../Utils';
import LocaleContext from '../../LocaleContext';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';


export default function NavBar() {

    const { locale } = useContext(LocaleContext);
    const { t } = useTranslation();

    const [topMenu, setTopMenu] = useState(true);
    const [FreightForwardingMenu, setFreightForwardingMenu] = useState(false);
    const [IntegratedLogisiticsMenu, setIntegratedLogisiticsMenu] = useState(false);
    const [ContactMenu, setContactMenu] = useState(false);

    const mapping: { [name: string]: { [name: string]: { name: string, href: string } } } = {
        "FreightForwarding": freightForwardingNavigations,
        "IntegratedLogisitics": integratedLogisticsNavigations,
        "Contact": contactNavigations
    }

    function changeLocale(l: string) {
        if (locale !== l) {
            i18n.changeLanguage(l);
        }
    }

    function hamburgerOnClick(key: string) {
        if (key == navigations["FreightForwarding"].name) {
            setTopMenu(false)
            setFreightForwardingMenu(true)
        }
        else if (key == navigations["IntegratedLogisitics"].name) {
            setTopMenu(false)
            setIntegratedLogisiticsMenu(true)
        }
        else if (key == navigations["Contact"].name) {
            setTopMenu(false)
            setContactMenu(true)
        }
    }

    function hamburgerResetOnClick() {
        setTopMenu(true)
        setFreightForwardingMenu(false)
        setIntegratedLogisiticsMenu(false)
        setContactMenu(false)
    }

    const back = <Disclosure.Panel
        onClick={() => hamburgerResetOnClick()}
        className="block px-3 py-2 rounded-md text-base font-medium text-black hover:bg-gray-700 hover:text-white cursor-pointer"
        aria-current='page'
    >
        <ChevronLeftIcon className="h-5 w-5 inline-block" />
    </Disclosure.Panel>

    return (
        <Disclosure as="nav" className="">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" onClick={() => hamburgerResetOnClick()} />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-start">
                                <div className="flex flex-shrink-0 items-center">
                                    <img
                                        className="block h-8 w-auto lg:hidden"
                                        src={logo}
                                        alt="Logo"
                                    />
                                    <img
                                        className="hidden h-8 w-auto lg:block"
                                        src={logo}
                                        alt="Logo"
                                    />
                                </div>
                                <div className="hidden sm:ml-6 lg:block">
                                    <div className="flex space-x-2">
                                        <ul className="menu menu-horizontal">
                                            {Object.keys(navigations).map((item) => (
                                                navigations[item].type == "button" && <li><a
                                                    key={navigations[item].name}
                                                    href={navigations[item].href}
                                                    className="flex px-3 py-2 text-sm md:text-sm md:px-2 tracking-tight text-black hover:text-hansa-dark-blue font-semibold"
                                                    aria-current='page'
                                                >
                                                    {t(navigations[item].name)}
                                                </a></li>
                                            ))}
                                            {Object.keys(navigations).map((item) => (
                                                navigations[item].type == "panel" && <li>
                                                    <button className='flex peer items-center px-3 py-2 text-sm md:text-sm md:px-2 tracking-tight text-black hover:text-hansa-dark-blue font-semibold cursor-pointer'>
                                                        {t(navigations[item].name)}
                                                        <svg className="ml-1 w-4 h-4 flex" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                                    </button>
                                                    
                                                    <ul className="flex hidden peer-hover:flex hover:flex flex-col bg-white drop-shadow-lg z-10 absolute">
                                                    {Object.keys(mapping[item]).map((i) => (
                                                            <li className='w-max px-1 pb-1'><a
                                                                key={mapping[item][i].name}
                                                                href={mapping[item][i].href}
                                                                className="px-3 py-2 text-sm md:text-sm md:px-3 md:py-2 tracking-tight text-black hover:text-hansa-dark-blue font-semibold"
                                                                aria-current='page'
                                                            >
                                                                <span>
                                                                    {t(mapping[item][i].name)}
                                                                </span>
                                                            </a></li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>
                                       
                                    </div>
                                </div>
                            </div>

                            {/* Language DropDown */}
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                <Menu as="div" className="relative ml-3">
                                    <div>
                                        <Menu.Button className="flex rounded-full text-sm">
                                            <span className="sr-only">Select Language</span>
                                            <LanguageIcon className="h-6 w-6" />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 cursor-pointer')}
                                                        onClick={() => changeLocale('zh-CN')}
                                                    >
                                                        简体中文
                                                    </a>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 cursor-pointer')}
                                                        onClick={() => changeLocale('en')}
                                                    >
                                                        English
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="lg:hidden">
                        <div className="space-y-1 px-2 pt-2 pb-3 shadow-inner">
                            {topMenu && Object.keys(navigations).map((item) => (
                                <>
                                    {navigations[item].type == "button" && <Disclosure.Button
                                        key={navigations[item].name}
                                        as="a"
                                        href={navigations[item].href}
                                        className="block px-3 py-2 rounded-md text-base font-medium text-black"
                                        aria-current='page'
                                    >
                                        {t(navigations[item].name)}
                                    </Disclosure.Button>}

                                    {navigations[item].type == "panel" && <Disclosure.Panel
                                        key={navigations[item].name}
                                        as="b"
                                        onClick={() => hamburgerOnClick(navigations[item].name)}
                                        className="block px-3 py-2 rounded-md text-base font-medium text-black cursor-pointer"
                                        aria-current='page'
                                    >
                                        <span>
                                            {t(navigations[item].name)}
                                            <ChevronDownIcon className="ml-1 w-4 h-4 inline-block" />
                                        </span>
                                    </Disclosure.Panel>}
                                </>
                            ))}
                            {FreightForwardingMenu && <>
                                {Object.keys(freightForwardingNavigations).map((item) => (
                                    <Disclosure.Button
                                        key={freightForwardingNavigations[item].name}
                                        as="a"
                                        href={freightForwardingNavigations[item].href}
                                        className="block px-3 py-2 rounded-md text-base font-medium text-black"
                                        aria-current='page'
                                    >
                                        {t(freightForwardingNavigations[item].name)}
                                    </Disclosure.Button>
                                ))}
                                <hr />
                                {back}
                            </>}
                            {IntegratedLogisiticsMenu && <>
                                {Object.keys(integratedLogisticsNavigations).map((item) => (
                                    <Disclosure.Button
                                        key={integratedLogisticsNavigations[item].name}
                                        as="a"
                                        href={integratedLogisticsNavigations[item].href}
                                        className="block px-3 py-2 rounded-md text-base font-medium text-black"
                                        aria-current='page'
                                    >
                                        {t(integratedLogisticsNavigations[item].name)}
                                    </Disclosure.Button>
                                ))}
                                <hr />
                                {back}
                            </>}
                            {ContactMenu && <>
                                {Object.keys(contactNavigations).map((item) => (
                                    <Disclosure.Button
                                        key={contactNavigations[item].name}
                                        as="a"
                                        href={contactNavigations[item].href}
                                        className="block px-3 py-2 rounded-md text-base font-medium text-black"
                                        aria-current='page'
                                    >
                                        {t(contactNavigations[item].name)}
                                    </Disclosure.Button>
                                ))}
                                <hr />
                                {back}
                            </>}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}